<template>
  <div>
    <Menu />

    <div class="container position-relative">
      <div class="py-5">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>
